export default {
    namespaced: true,
    state: () => {
        return {
            userinfo: {
               
                token: '123456',
                account: '张三',
                password:'123456'

            }
        }
    },
    mutations:{
        updateName(state,payload){
            state.userinfo.account=payload;
        },
        updatePassword(state,payload){
            state.userinfo.password=payload;
        }
    },
    actions:{
        updateNameAsync({commit}){
            setTimeout(() =>{
                commit('updateName',payload)
            },2000);
        },
        updateNameAsync({commit}){
            setTimeout(() =>{
                commit('updatePassword',payload)
            },2000);
        }

    }
    
}