<template>
  <div class="login_container">
    <div class="login_box">
      <h2 class="pagename">用户登录</h2>
      <el-form
        ref="loginformRef"
        :model="dataForm"
        label-width="0px"
        :rules="loginRules"
        class="login_form"
      >
        <el-form-item prop="account">
          <el-input v-model="dataForm.account" type="text"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="dataForm.password" type="password"></el-input>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" @click="loin">登录</el-button>
          <el-button type="info" @click="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name:"Login",
      dataForm: {
        account: "",
        password: "",
      },
      loginRules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    resetLoginForm() {
      this.$refs.loginformRef.resetFields();
    },
    loin() {
      this.$refs.loginformRef.validate(async (valid) => {
        if (!valid) return;
        const { data: res } = await this.$http.post("login", this.dataForm);
if(res.code!==200){
  this.$message.error({message:"登录失败"})
}else{
  this.$message.success({message:"登录成功"});
  window.sessionStorage.setItem('token',res.data);
  this.$router.push('/login');
}
        
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login_container {
  background-color: #2b4b6b;
  height: 100%;
}
.login_box {
  width: 450px;
  height: 300px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
.btns {
  display: flex;
  justify-content: flex-end;
}
.pagename {
  margin-left: 15px;
}
</style>