import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home/Home.vue'
//import Index from '../components/Index.vue'
import Layout from '../views/Layout.vue'
import Login from '../views/Login.vue'
import DataView from '../views/DataView.vue'
import SuspensionGiven from '../views/Belts/SuspensionGiven.vue'
import SuspensionFlow from '../views/Belts/SuspensionFlow.vue'
Vue.use(VueRouter)

const routes = [
  {   
    
    path:'/',
    component: Layout,
    children:[
      {path:'/',name:'index',component:Home},
      {path: '/datacenter', name: 'datacenter', component: DataView },

        {path:'/suspensiongiven',name:'全悬浮电子皮带给料秤',component:SuspensionGiven},
        {path:'/suspensionflow',name:'全悬浮电子皮带流量秤',component:SuspensionFlow},
        {path:'/doubleroller',name:'双托辊电子皮带秤',component:Home},
        {path:'/fourroller',name:'四托辊电子皮带秤',component:Home},
        {path:'/arraybeltscale',name:'阵列式电子皮带秤',component:Home},
        {path:'/acticarbonlittle',name:'活性炭小微流量皮带给料秤',component:Home}
      ,
      {path: '/solutions',  component: Home,children:[
        {path:'/fourroller',name:'电子皮带秤方案',component:Home},
        {path:'/arraybeltscale',name:'皮带秤定量给料方案',component:Home},
        {path:'/acticarbonlittle',name:'料斗定量给料控制方案',component:Home}
      ] },
      {path: '/softwares',  component: Home ,children:[
        {path:'/suspensiongiven',name:'无人值守汽车衡称重软件',component:Home},
        {path:'/suspensiongiven',name:'定量称重给料控制软件',component:Home},
        {path:'/suspensionflow',name:'污水处理数据采集软件',component:Home},
        {path:'/doubleroller',name:'钢材称重显示软件',component:Home},
        {path:'/fourroller',name:'一机多秤集中称重软件',component:Home},
        {path:'/arraybeltscale',name:'农产品溯源防伪系统软件',component:Home},
        {path:'/acticarbonlittle',name:'粮食收购交易软件',component:Home}
      ]},
      {path: '/technologys', name:'软件使用说明书',component: Home,children:[
        {path:'/suspensiongiven',name:'称重结构设计',component:Home},
        {path:'/suspensionflow',name:'仪表说明书',component:Home},
        {path:'/doubleroller',name:'操作流程',component:Home},
        {path:'/fourroller',name:'皮带秤校准',component:Home},
        {path:'/arraybeltscale',name:'料斗秤校准',component:Home},
        {path:'/acticarbonlittle',name:'通讯与设备组网',component:Home}
      ] }
    ]
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:Login
  },
  {path: '/about', name: 'about', component:() => import( '../views/AboutView.vue') },
  {path: '/beltdata', name: 'beltdata', component:() => import( '../views/BeltData.vue') ,meta:{title:'富源县煤矿企业井口计量监控'}}

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router

