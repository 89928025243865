<template>
  <div class="w">
    <br />
    <H1>{{ title }}</H1>
    <br />
    <H3>原理</H3>
    <span>当需要进行流量调节时，可以结合变频器控制。通过设定（给定）流量，Q（t/h），结合当前速度V(/s)，计算输出电流AO。载荷计算： q = Q / 3.6 / v ，如果当前秤台上重量为空，仪表一般输出最大电流20mA，随着流量逐步接近目标流量时，输出的电流也逐步变小，最后在目标值附近上下波动。</span><br>
    <H3>适用工作环境</H3>
    <span>需要定量给料时</span><br>
    <H3>设计图</H3>
    <H3>实物</H3>
    <H3>现场图片</H3>
    <H3>使用结果</H3>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      title: "全悬浮电子皮带秤---流量控制",
    };
  },
};
</script>
  
<style>
h1{
  margin-left: 300px ;
}
</style>