<template>
  <div>
    <top-nav></top-nav>
    <div class="header w">
      <base-main/>       
    </div>
    <!--二级路由出口-->
    <router-view />
    <div class="w"></div>
    <br/><br/><br/><br/><br/><br/><br/>
  <div class="footer">
    <base-footer></base-footer>
  </div>
  </div>
</template>

<script>
import TopNav from "@/components/TopNav.vue";
import MyHeader from "../components/MyHeader.vue";
import BaseMain from "../components/BaseMain.vue";
import BaseFooter from '../components/BaseFooter.vue';
export default {
  name: "Layout",
  components: {
    TopNav,
    MyHeader,
    BaseMain,
    BaseFooter,
  },
};
</script>

<style>
.header{
  width: 100%;
    height: 40px;
    line-height: 50px;
    background:#2b4b6b;
    text-align: center;
}
.main{
  height: calc(100vh - 50px);
  position: relative;
  top: 50px;
  overflow-y: scroll;
}
.footer {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    background: #2b4b6b;
    text-align: center;
    position: fixed;
    bottom: 0;
}
</style>