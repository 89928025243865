<template>
  <div>
    <div>
      <ul>
        <li>
          <el-date-picker v-model="value2" align="left" type="date" placeholder="选择日期"  :picker-options="pickerOptions">
          </el-date-picker>
        </li>
        <li><el-input placeholder="车号" v-model="carno" clearable style="width: 250px;"></el-input></li>
        <li><el-input placeholder="货物名称" v-model="goodsname" clearable style="width: 250px;"></el-input></li> 
        <li><el-input placeholder="收货单位" v-model="input" clearable style="width: 350px;"></el-input></li>      
      </ul>
      <ul> 
       
        <li><el-input placeholder="发货单位" v-model="input" clearable style="width: 250px;"></el-input></li>
        <li><el-button type="primary">查询</el-button></li>
        <li><el-button type="info">导出</el-button></li>        
      </ul>
    </div>
    <div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column fixed prop="date" label="日期" width="150">
        </el-table-column>
        <el-table-column prop="plate" label="车牌号" width="100">
        </el-table-column>
        <el-table-column prop="gross" label="毛重" width="80">
        </el-table-column>
        <el-table-column prop="tare" label="皮重" width="80"> </el-table-column>
        <el-table-column prop="net" label="净重" width="80"> </el-table-column>
        <el-table-column prop="opgross" label="毛重司磅员" width="120">
        </el-table-column>
        <el-table-column prop="optare" label="皮重司磅员" width="120">
        </el-table-column>
        <el-table-column prop="grosstime" label="毛重时间" width="200">
        </el-table-column>
        <el-table-column prop="taretime" label="皮重时间" width="200">
        </el-table-column>
        <el-table-column prop="goodsname" label="货物名称" width="200">
        </el-table-column>
        <el-table-column prop="sallerunit" label="发货单位" width="200">
        </el-table-column>
        <el-table-column prop="purunit" label="收货单位" width="200">
        </el-table-column>
        <el-table-column prop="scaleno" label="秤编号" width="80">
        </el-table-column>
        <el-table-column prop="factoryno" label="厂编号" width="80">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
              >查看</el-button
            >
            <el-button type="text" size="small">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataView",
  data() {
    return {
      input:'',
      carno:'',
      goodsname:'',
      tableData: [
        {
          date: "2022-05-02",
          plate: "云A3325A",
          gross: "50",
          tare: "19.5",
          net: "30.5",
          opgross: "韩梅",
          optare: "韩梅",
          grosstime: "2022-05-02 10:15:15",
          taretime: "2022-05-02 10:20:10",
          goodsname: "煤",
          sallerunit: "XXXX煤矿",
          purunit: "xxxx化工厂",
          scaleno: "0101",
          factoryno: "01",
        },
        {
          date: "2022-05-02",
          plate: "云A77895",
          gross: "50",
          tare: "19.5",
          net: "30.5",
          opgross: "韩梅",
          optare: "韩梅",
          grosstime: "2022-05-02 10:15:15",
          taretime: "2022-05-02 10:20:10",
          goodsname: "煤",
          sallerunit: "XXXX煤矿",
          purunit: "xxxx化工厂",
          scaleno: "0101",
          factoryno: "01",
        },
        {
          date: "2022-05-02",
          plate: "云F4234C",
          gross: "50",
          tare: "19.5",
          net: "30.5",
          opgross: "韩梅",
          optare: "韩梅",
          grosstime: "2022-05-02 10:15:15",
          taretime: "2022-05-02 10:20:10",
          goodsname: "煤",
          sallerunit: "XXXX煤矿",
          purunit: "xxxx化工厂",
          scaleno: "0101",
          factoryno: "01",
        },
        {
          date: "2022-05-02",
          plate: "云D2285F",
          gross: "50",
          tare: "19.5",
          net: "30.5",
          opgross: "韩梅",
          optare: "韩梅",
          grosstime: "2022-05-02 10:15:15",
          taretime: "2022-05-02 10:20:10",
          goodsname: "煤",
          sallerunit: "XXXX煤矿",
          purunit: "xxxx化工厂",
          scaleno: "0101",
          factoryno: "01",
        },
      ],
      pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }, {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          }, {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }]
        },
        value1: '',
        value2: '',
      
    }
  },

  methods: {
    handleClick(row) {
      console.log(row)
    },
  },
  created () {
   
  },
}
</script>
<style lang="less" scoped>
    ul{        
        display: flex;
        height: 40px;
        line-height: 40px;
        justify-content: flex-start;
        margin: 10px;
        padding: 10px 10px;
        li{
            el-input{   
                           
                color: #ccc;
                border-left:1px solid #ccc ;
            }
        }
        li:nth-child(1){
            a{
                border-left:0;
            }
        }
    }
</style>