<template>
  <div class="w">    
      <el-menu
        :default-active="activeIndex2"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        background-color="#fff"
        text-color="#333"
        router
        active-text-color="#00cc00"
      >
      <el-submenu index="1">
          <template slot="title">电子皮带秤</template>
          <el-menu-item index="/suspensiongiven">全悬浮电子皮带给料秤</el-menu-item>
          <el-menu-item index="/suspensionflow">全悬浮电子皮带流量秤</el-menu-item>
          <el-menu-item index="/doubleroller">双托辊电子皮带秤</el-menu-item>
          <el-menu-item index="/fourroller">四托辊电子皮带秤</el-menu-item>
          <el-menu-item index="/arraybeltscale">阵列式电子皮带秤</el-menu-item>
          <el-menu-item index="/acticarbonlittle">活性炭小微流量皮带给料秤</el-menu-item>
        </el-submenu>
        <el-submenu index="2">
          <template slot="title">解决方案</template>
          <el-menu-item index="2-1">电子皮带秤方案</el-menu-item>
          <el-menu-item index="2-2">皮带秤定量给料方案</el-menu-item>
          <el-menu-item index="2-2">料斗定量给料控制方案</el-menu-item>
        </el-submenu>
        <el-submenu index="3">
          <template slot="title">应用软件</template>
          <el-menu-item index="3-1">无人值守汽车衡称重软件</el-menu-item>
          <el-menu-item index="3-2">定量称重给料控制软件</el-menu-item>
          <el-menu-item index="3-3">污水处理数据采集软件</el-menu-item>
          <el-menu-item index="3-4">钢材称重显示软件</el-menu-item>
          <el-menu-item index="3-5">一机多秤集中称重软件</el-menu-item>
          <el-menu-item index="3-6">农产品溯源防伪系统软件</el-menu-item>
          <el-menu-item index="3-7">粮食收购交易软件</el-menu-item>
        </el-submenu>
        <el-menu-item index="/datacenter">
          数据中心
        </el-menu-item>
        <el-submenu index="5">
          <template slot="title">技术支持</template>
          <el-menu-item index="5-1">软件使用说明书</el-menu-item>
          <el-menu-item index="5-2">称重结构设计</el-menu-item>
          <el-menu-item index="5-3">仪表说明书</el-menu-item>
          <el-menu-item index="5-4">操作流程</el-menu-item>
          <el-menu-item index="5-5">皮带秤校准</el-menu-item>
          <el-menu-item index="5-6">料斗秤校准</el-menu-item>
          <el-menu-item index="5-7">通讯与设备组网</el-menu-item>
        </el-submenu>
      </el-menu>    
    <div class="main_content"></div>
  </div>
</template>

  <script>
export default {
  name: "BaseMain",
  data() {
    return {
      drawer: false,
      direction: "btt",
    };
  },
  methods: {},
};
</script>
  
<style>

.el-menu{
    /*align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
}
</style> 