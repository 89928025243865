import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios' //第一
import VueAxios from 'vue-axios' //第二 否则报错
import './assets/style/base.css'
import './assets/style/mixins.less'
import './assets/style/variables.less'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueAxios,axios)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
