<template>
    <div class="footer">
        <a href="https://beian.miit.gov.cn/" target="_blank">滇ICP备2022007432号-1</a>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="less" scoped>

</style>