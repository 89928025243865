<template>
  <div class="app-top-nav">
    <div class="w">
      <span style="font-size: 26px;width: 350px;float: left; color: #ffb302;">云南昌达物联科技有限公司</span>     
      <ul>        
        <template v-if="true">
            <li><a href="javascript:;">--昌达物联--</a></li>
            <li><a href="javascript:;">退出</a></li>
        </template>
        <li v-else><a href="javascript:;">登录/注册</a></li>
        <li><a href="javascript:;">个人中心</a></li>
        <li><a href="javascript:;">
            <i class="iconfont icon-phone"></i>
            App</a></li>
        <li><router-link to="/about">关于</router-link></li>
        <li><router-link to="/beltdata">皮带秤数据</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopNav",
};
</script>

<style lang="less" scoped>
.app-top-nav{
    background-color: #2b4b6b;
    ul{
        
        display: flex;
        height: 40px;
        line-height: 40px;
        justify-content: flex-end;
        li{
            a{
                padding: 0 10px;
                color: #ccc;
                border-left:1px solid #ccc ;
                &:hover{
                   color: #fff;
                  //color: @warnColor;
                }
            }
        }
        li:nth-child(1){
            a{
                border-left:0;
            }
        }
    }
    
}
</style>