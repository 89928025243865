<template>
  <div class="my-header w">
    <!--上部-->
    <div class="header-top">
      <h1 class="header-logo">
        <router-link to="/"><img src="../assets/images/logotext.png"><img></router-link>
      </h1>
      <div class="header-center">
      <div search_center>
        <input type="text" name id placeholder="输入内容"/>
        <button class="search_btn">搜索</button>
      </div>
      </div>
      <div class="header-right"></div>
    </div>
  </div>
</template>
  <script>
export default {
  name: "MyHeader",
  data() {
    return {};
  }
};
</script>

<style lang="less" scoped>
.my-header {
  height: 120px;
  padding: 25px 0 0 0;
  background-color: #fff;
  .header-top {
    display: flex;

    .header-logo {
      width: 230px;
      height: 86px;
      a{
        display: inline-block;
        height: 230px;
        width: 86px;        
      }
    }
    .header-center {
      flex: 1;
      .search_center{
        
        width: 640px;
        height: 36px;
        input{
          
          height: 36;
          width:540;
          padding-left:15px ;
          border: 1px solid #666;
          box-sizing: border-box;
          border-radius: 20px 0 0 20px;
        }
        .search_btn{
          float:right;
          width: 100px;
          height: 35px;
          color: fff;
          background-color: antiquewhite;
          border-radius: 0 20px 20px 0;
          border: none;
        }

      }

    }
    .header-right {
      background-color: blue;
    }
  }
}
</style>