<template>
    <div class="w">
    <br />
    <H1>{{ title }}</H1>
    <br />
    <H3>原理</H3>
    <img src="../../assets/images/belt2.png" style="width: 1100px;height: 100%;">
    <span>为了解决物料连续给料，同时要知道重量的场合。其原理是：物料在输送带上输送，通过测力元件（称重传感器）得到重量信号，由于物料是连续输送的，作用在传感器上的垂直分力随物料多少、皮带厚薄、输送速度等因素的变化而连续变化，
        因此除了要知道某个瞬间产生的垂直分力，还要测量皮带的速度，测得质量与速度之后，再用速度*（质量）流量=瞬时流量。在一段时间内，以固定时间间隔连续计算该值，并累加，最后得到一个累计重量，完成连续计量功能。</span>
        <br /><span>以上描述用一个表达式来描述大概（为什么是大概？后面逐步介绍）是这样的: Q(t0)*V(t0)+Q(t1)*V(t1)+...+Q(tn)*V(tn)=M(tn)。Q(t0)、V（t0）代表开始采样时刻对应的流量和速度；Q(t1)、V（t1）代表第二次采样时刻对应的流量和速度；
        Q(tn)、V（tn）代表第n次采样时刻对应的流量和速度(Kg/s)；这样就实现累计(Kg)功能。</span><br />
        <span>那么问题又来了，重量怎么来取？速度呢？一般皮带秤大多采用嵌入式安装，称重部分安装在皮带机机架上，称重部分有一部分长度。这个长度叫做计量段长度，关于计量段长度网上是众说纷纭，其实计量段长度应该是物料引起重量变化的那一段长度。哪一段会影响呢？理论上，整条皮带都会有影响，但如何来取舍？我们就以影响最明显的那段开始（与称重部分相邻的两对托辊之间的那段距离用来进行实际的计算）。比如秤占了2个托辊，每组托辊间距是1m，两个托辊间1m，秤前后各一组，此时的计量段长度应该是3m。物料在这个长度范围内，作用在秤上的力由小到大，再由大到小变化，控制好这个变化过程可以获得较好的精度。当物料经过计量段区间，会采样n多次，因此，我们不能直接把传感器传过来的实际值拿来算，那会计算出一个很大的值，此时计量段长度就要参与进来。如何参与？用传感器输出的值（Kg）/计量段长度(m) = 平均值（Kg/m），再用均值*V（m/s），这样就得到真实质量流量（Kg/s）。但这样还有问题，我们需要对速度也进行处理，为什么？
            因为皮带上的物料是运动的变化的，我们需要把时间细分，如何细分？1s=1000ms;如果100ms采样一次，1秒钟内会采样10次。计算秒累计：前面均值再/10:如果是500ms/次则计算出来的误差会更大。原则上分得越细，精度越高，但太高的精度对整个系统都高了。如果是PLC，把采样放在定时中断程序里做，
            如果定时时间太短，比如（1ms）,那PLC别的活(通讯、数据滤波)大概都不要干了。一般地10次基本上够用了。</span><br/>
        <span>但是在生产中，实际测得的值并不能反映出真实的状态，有很多偶然因素需要考虑：机械振动、皮带打滑、皮带张力变化、皮带厚薄变化、皮带接头好坏均会或多或少的产生一些影响，因此需要对这些数据进行一定程度的数据处理。一般地：都会对这些数据采取滤波算法，消除一些偶然因素。再乘以一个系数，比如：质量系数、速度系数、累计量系数来进行数字修正。</span>
        
    <H3>适用工作环境</H3>
    <H3>设计图</H3>
    <H3>实物</H3>
    <H3>现场图片</H3>
    <H3>使用结果</H3>
    </div>
    
  </template>
  
  <script>
  export default {
  data(){
    return{
        title:'全悬浮电子皮带秤'
    }
  }
  }
  </script>
  
  <style>
  span{
    margin-left:30px ;
    line-height: 28px;
  }
  </style>